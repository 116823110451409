const idPortalApi = "07fb288cbdff1c94895af7993b81197cce41f502";

module.exports = {
  tokenLink: idPortalApi,
  urlApi: `https://api-portais-workcenter.com.br/${idPortalApi}`,
  link: "http://www.transparenciadministrativa.com.br",
  urlADM: `https://administracaopublica.com.br`,
  linkArquivos: "http://www.transparenciadministrativa.com.br/portalimagem",
  imagemURL: "https://arquivos-api-portais.s3.sa-east-1.amazonaws.com/",

  // urlApi: `http://localhost:8004/${idPortalApi}`,
  //imagemURL: 'http://localhost:8004/uploads/', //link para a imagem

  urlEmDeploy: "https://cmsantafilomena.ma.gov.br/", //usada para link de compartilhamento
  dnsPortal: "cmsantafilomena.ma.gov.br",
  NomePortal: "Santa Filomena",
  ufPortal: "MA",
  NomeTipoPortal: "Câmara Municipal", //Prefeitura ou Câmara
  TipoPortal: 4, //3 = Prefeitura 4 = Câmara

  //Variável em teste (newsViewRoute)
  newsViewRoute: true,
  countNewsHome: 4, //quantidade de noticias na home
  RoutesDaily: true, //Deixa ativado as rotas para a pagina de diario
  corIcones: "#b28e08",

  //links externos
  link_youtube: `https://www.planalto.gov.br/ccivil_03/leis/l9504.htm`,
  link_localize: `https://maps.app.goo.gl/fX212JpzW6hBqPDm7`,
  //Mapa da pagina Municipio/Mapa
  link_mapa:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15885.722146223174!2d-44.56567914000103!3d-5.5029136628914035!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x78870b3fe2a78b9%3A0xb2fa4fb32f23df6d!2sSanta%20Filomena%20do%20Maranh%C3%A3o%2C%20MA%2C%2065768-000!5e0!3m2!1spt-BR!2sbr!4v1721924501957!5m2!1spt-BR!2sbr",
};
